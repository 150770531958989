<template>
  <div id="banner">
    <!-- 导航1 -->
    <el-breadcrumb>
      <el-breadcrumb-item>
        <router-link to="./">首页</router-link>
      </el-breadcrumb-item>
      <el-breadcrumb-item>Banner管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体内容2 -->
    <div class="content">
      <!-- 搜索框sel2.1 -->
      <el-form
        inline
        label-position="right"
        label-width="60px"
        size="small"
        class="query-form"
        @submit.native.prevent="getList()"
      >
        <el-form-item label="搜索">
          <el-input v-model="query.key" placeholder="请输入关键词"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="selClick()">查询</el-button>
          <el-button type="success" @click="addClick()">新增</el-button>
        </el-form-item>
      </el-form>
      <!-- 列表框2.2 -->
      <el-card class="box-card table">
        <div slot="header" class="clearfix">
          <span>Banner列表</span>
        </div>
        <el-tabs v-model="query.type" @tab-click="getList">
          <el-tab-pane
            v-for="item in typeSelList"
            :label="item.name"
            :key="item.id"
            :name="item.id.toString()"
          ></el-tab-pane>
        </el-tabs>
        <!-- 列表 -->
        <el-table
          v-loading="loading"
          :data="tableData"
          ref="tableData"
          border
          stripe
          style="width: 100%"
          @sort-change="sortChange"
          @filter-change="filterTag"
        >
          <el-table-column prop="title" label="标题"></el-table-column>
          <el-table-column label="图片">
            <template slot-scope="scope" width="150px">
              <img
                :src="domain + scope.row.imgUrl"
                :style="{ height: '50px' }"
                alt
              />
            </template>
          </el-table-column>
          <el-table-column prop="linkUrl" label="链接"></el-table-column>
          <el-table-column prop="sort" label="排序"></el-table-column>
          <el-table-column prop="summary" label="备注"></el-table-column>
          <!-- <el-table-column prop="type" label="类型"></el-table-column> -->
          <el-table-column prop="isEnable" label="是否可用">
            <template slot-scope="sc">
              <el-tag v-if="sc.row.isEnable" type="success">是</el-tag>
              <el-tag v-else type="warn">否</el-tag>
            </template>
          </el-table-column>
          <el-table-column prop="createTime" label="添加时间"></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button-group>
                <el-tooltip content="编辑" placement="top">
                  <el-button
                    type="primary"
                    size="mini"
                    icon="el-icon-edit"
                    @click="editClick(scope.row)"
                  ></el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="danger"
                    size="mini"
                    icon="el-icon-delete"
                    @click="delClick(scope.row)"
                  ></el-button> </el-tooltip
              ></el-button-group>
            </template>
          </el-table-column>
        </el-table>
        <!-- 分页条page -->
        <div class="block" style="margin-top: 20px">
          <el-pagination
            background
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage4"
            :page-size="pageSize"
            :page-sizes="[10, 20, 30, 50, 100]"
            layout="total,prev,sizes,pager,next,jumper"
            :total="total"
          ></el-pagination>
        </div>
      </el-card>
    </div>
    <!-- 操作对话框3 -->
    <el-dialog
      :title="form.id > 0 ? '编辑Banner' : '添加Banner'"
      :visible.sync="dialogVisible"
      @opened="dialogOpened()"
    >
      <el-form
        :model="form"
        ref="form"
        label-width="100px"
        :status-icon="true"
        @submit.native.prevent="submitForm(form)"
      >
        <!-- 内容3.1 -->
        <el-form-item
          label="标题"
          prop="title"
          :rules="[{ required: true, message: '请输入标题,最多30个字' }]"
        >
          <el-input v-model="form.title" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item
          prop="type"
          label="类型"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <el-select
            placeholder="请选择Banner类型"
            v-model="form.type"
            style="width: 100%"
            clearable
            filterable
          >
            <el-option
              v-for="item in typeSelList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- 图片框 -->
        <el-form-item
          label="图片"
          :rules="[{ required: true, message: '不能为空' }]"
        >
          <div id="uploadBtn1" class="uploader uploader-warning"></div>
          <img
            id="relativeName"
            :src="domain + form.imgUrl"
            style="height: 40px; position: relative; left: 10px; top: 20px"
            v-if="form.imgUrl"
          />
          <div class="uploader-text">
            * Banner尺寸：
            <span v-if="form.type == 1">推荐930px*370px </span>
            <span v-else-if="form.type == 2">推荐750px*350px </span>
            <span v-else-if="form.type == 3">推荐300px*200px </span>
            <span v-else-if="form.type==4">推荐120px*120px </span>
            <span v-else-if="form.type == 5">推荐300px*200px </span>
            <span v-else>推荐780px*208px </span>
          </div>
        </el-form-item>
        <el-form-item
          label="网址"
          prop="linkUrl"
          :rules="[{ required: false }]"
        >
          <el-input v-model="form.linkUrl" placeholder="请输入网址"></el-input>
        </el-form-item>
        

        <el-form-item prop="artid" label="文章关联">
          <el-select
            placeholder="请选择关联文章"
            v-model="form.gooid"
            style="width: 100%"
            clearable
            filterable
            @change="artSelect"
          >
            <el-option
              v-for="item in stralist"
              :key="item.id"
              :label="item.title"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item
          label="链接"
          prop="artLink"
        >
          <el-input v-model="form.artLink" placeholder="请输入链接"></el-input>
        </el-form-item>

        <el-form-item
          label="排序"
          prop="sort"
          :rules="[{ type: 'number', message: '请填写数字' }]"
        >
          <el-input
            v-model.number="form.sort"
            placeholder="请输入排序字段"
          ></el-input>
        </el-form-item>

        <el-form-item
          label="备注"
          prop="summary"
          :rules="[{ required: false }]"
        >
          <el-input v-model="form.summary" placeholder="请输入备注"></el-input>
        </el-form-item>

        <!-- 布尔选择框 -->
        <el-row :gutter="20">
          <el-col :span="6">
            <el-form-item label="是否可用">
              <el-switch v-model="form.isEnable"></el-switch>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item>
          <el-button type="primary" :loading="submiting" native-type="submit"
            >提交</el-button
          >
          <el-button @click="dialogVisible = false">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import url from "@/plugins/urlHelper.js";

export default {
  data() {
    return {
      domain: url.getDomain(),
      query: { type: "1" },
      form: {},
      tableData: [],
      total: 0,
      dialogVisible: false,
      loading: false,
      submiting: false,
      currentPage4: 1,
      pageSize: 10,
      typeSelList: [],
      stralist:[],//攻略
    };
  },
  created() {
    // 攻略
    this.getAllStrategy();
    // 类型
    this.getBannerType();
    // 列表
    this.getList();
  },
  methods: {
    /**获取类型列表 */ getBannerType: function () {
      var _this = this;
      var link = url.getSys("getsellist_banner");
      $.get(link, {}, (res) => {
        _this.typeSelList = res;
      });
    },
    handleSizeChange: function (val) {
      this.pageSize = val;
      this.currentPage41;
      this.getList();
    },
    handleCurrentChange: function (val) {
      this.currentPage4 = val;
      this.getList();
    },
    // 排序
    sortChange: function (sortColumn) {
      this.query.sort = sortColumn.sort;
      this.query.order = sortColumn.order;
      this.getList();
    },
    // 筛选
    filterTag: function (filter) {
      var val = filter["isEnable"][0];
      this.getList();
    },
    //查询
    selClick() {
      this.currentPage4 = 1;
      this.getList();
    },
    // 新增
    addClick() {
      this.dialogVisible = true;
      this.form = {
        isEnable: true,
      };
    },
    // 获取攻略
    getAllStrategy(){
      var _this=this;
      var link=url.getStrategy('GetAllStrategy');
      $.get(link,{},data=>{
        _this.stralist=data;
      })
    },
    //列表方法
    getList() {
      var _this = this;
      _this.loading = true;
      var params = _this.query;
      params.pageSize = _this.pageSize;
      params.pageIndex = _this.currentPage4;
      var link = url.getSys("getlist_banner");
      $.get(link, params, (data) => {
        _this.loading = false;
        _this.tableData = data.list;
        _this.total = data.total;
      });
    },
    // 修改
    editClick(row) {
      this.form = Object.assign({}, row);
      this.dialogVisible = true;
    },
    // 删除
    delClick:function(row) {
      var _this = this;
      var link = url.getSys("delete_banner");
      var id = row.id;

      this.$confirm("确定要删除吗？", "提示", {
        confirmButtonText: "确定",
        concelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          $.post(link, { ids: id }, (res) => {
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({
                type: "success",
                message: res.msg,
              });
            } else {
              _this.$message({
                type: "error",
                message: res.msg,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //上传图片
    dialogOpened: function () {
      var _this = this;

      // 上传图片
      if ($("#uploadBtn1 input").length == 0) {
        $("#uploadBtn1").uploader({
          url: url.getUpload(),
          text: "上传图片",
          fileExts: "jpg;png",
          handleType: "0",
          maxSize: 1024 * 1024 * 1,
          onSuccess: function (data) {
            var src = url.getDomain() + data.relativeName;
            _this.$set(_this.form, "imgUrl", data.relativeName);
          },
        });
      }
    },
    // 提交
    submitForm(formName) {
      var _this = this;
      var link = url.getSys("addOrUpdate_banner");
      var info = this.form;
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.submiting = true;
          //提交后台
          $.post(link, info, (res) => {
            _this.submiting = false;
            if (res.status) {
              _this.dialogVisible = false;
              _this.getList();
              _this.$message({ type: "success", message: res.msg });
            } else {
              _this.$message({ type: "error", message: res.msg });
            }
          });
        } else {
          return false;
        }
      });
    },
    //选择结果
    artSelect(e) {
      var _this = this;
      var artid = e;
      var artList = this.stralist;
      var thisItem = null;
      for (var i = 0; i < artList.length; i++) {
        var item = artList[i];
        if (item.id == artid) {
          thisItem = item;
          break;
        }
      }
      this.form.artLink = "/pages/Japan/informationinfo/informationinfo?id=" + artid;
      this.form.summary = item.title;
    },
  },
};
</script>